<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="标题" prop="title">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="icp">
        <el-input v-model="info.icp" placeholder="请输入icp"></el-input>
      </el-form-item>
      <el-form-item label="版权信息">
        <el-input v-model="info.copyright" placeholder="请输入版权信息"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="info.mobile" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        info: {
          title: '',
          icp: '',
          copyright: '',
          mobile: ''
        },
        source_domain: this.config.SOURCE_DOMAIN,
        rules: {
          title: [
            { required: true, message: '请输入标题', trigger: 'blur' }
          ]
        }
      };
    },
    created() {
      this.getInfo()
    },
    mounted() {
      //
    },
    computed: {
    },
    methods: {
      getInfo() {
        var that = this
        this.$api.merchant.sysDetail( function (res) {
          if(res.errcode == 0) {
            that.info = res.data
          }
        })
      },
      submitForm(formName) {
        var that = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            var param = {
              title: that.info.title,
              icp: that.info.icp,
              copyright: that.info.copyright,
              mobile: that.info.mobile
            }
            this.$api.merchant.sysEdit(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            return false;
          }
        });
      },
    }
  };
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 600px;
  }
</style>
